$amaranth-color: #ed3561;

.ds-cf7f7f7 {
  color: #f7f7f7;
}

.ds-cffffff {
  color: #ffffff;
}

.ds-cFFFFFFCB {
  color: #ffffffcb;
}
.ds-cBABABA {
  color: #bababa;
}

.ds-bgFAFAFA {
  background-color: #fafafa;
}

.ds-bgE9E9E9 {
  background-color: #e9e9e9;
}

.ds-c888888 {
  color: #888888;
}

.ds-c6B6B6B {
  color: #6b6b6b;
}

.ds-c000000 {
  color: #000000;
}

.ds-c1E9848 {
  color: #1e9848;
}

.ds-c40B256 {
  color: #40b256;
}

.ds-c727272 {
  color: #727272;
}

.ds-c5F5F5F {
  color: #5f5f5f;
}

.ds-c5D5D5E {
  color: #5d5d5e;
}

.ds-bg1E9848 {
  background-color: #1e9848;
}

.ds-bgf8f8f8 {
  background-color: #f8f8f8;
}

.ds-bgF6F6F7 {
  background-color: #f6f6f7;
}

.ds-c2F2F2F {
  color: #2f2f2f;
}

.ds-c767676 {
  color: #767676;
}

.ds-c656565 {
  color: #656565;
}

.ds-c797979 {
  color: #797979;
}

.ds-c838383 {
  color: #838383;
}

.ds-cA8A8A8 {
  color: #a8a8a8;
}

.ds-c666666 {
  color: #666666;
}

.ds-c8a8a8a {
  color: #8a8a8a;
}

.ds-cBEBEBE {
  color: #bebebe;
}

.ds-c44454A {
  color: #44454a;
}

.ds-c47A525 {
  color: #47a525;
}

.ds-c24AC21 {
  color: #24ac21;
}

.ds-c05BE00 {
  color: #05be00;
}

.ds-cE78923 {
  color: #e78923;
}

.ds-cD98E27 {
  color: #d98e27;
}

.ds-cF4891E {
  color: #f4891e;
}

.ds-c6A6A6A {
  color: #6a6a6a;
}

.ds-c797A7C {
  color: #797a7c;
}

.ds-c333333DE {
  color: #333333de;
}

.ds-c6A6464 {
  color: #6a6464;
}

.ds-c818181 {
  color: #818181;
}

.ds-c8D8D8D {
  color: #8d8d8d;
}

.ds-c868686 {
  color: #868686;
}

.ds-c656363 {
  color: #656363;
}

.ds-cF4071B {
  color: #f4071b;
}

.ds-cFF4545 {
  color: #ff4545;
}

.ds-cF54C52 {
  color: #f54c52;
}

.ds-c3D6AFD {
  color: #3d6afd; //primary blue
}

.ds-b3D6AFD {
  background-color: #3d6afd; //primary blue
}

.ds-bgffffff {
  background: #ffffff;
}

.ds-c131313 {
  color: #131313;
}

.ds-c363636 {
  color: #363636;
}

.ds-bgf7f7f7 {
  background: #f7f7f7;
}

.ds-bg1D1D20 {
  background-color: #1d1d20;
}

.ds-c86868B {
  color: #86868b;
}

.ds-bgEBF0FF {
  background-color: #ebf0ff;
}

.ds-bg86868B {
  background-color: #86868b;
}

.ds-bg3770E3 {
  background-color: #3770e3;
}

.ds-bg2F80ED {
  background-color: #2f80ed;
}

.ds-c3770E3 {
  color: #3770e3;
}

.ds-cB7B7B7 {
  color: #b7b7b7;
}

.ds-c696969 {
  color: #696969;
}

.ds-c6C6666 {
  color: #6c6666;
}

.ds-b3770E3 {
  border-color: #3770e3;
}

.ds-bgE78923 {
  background: #e78923;
}

.ds-bg000000 {
  background: #000000;
}

// Lime Green
.ds-bg32C92F {
  background-color: #32c92f;
}

.ds-c32C92F {
  color: #32c92f;
}

.ds-b32C92F {
  border-color: #32c92f;
}

.ds-c17650C {
  color: #17650c;
}

.ds-c009B45 {
  color: #009b45;
}

.bg-amaranth-color {
  background-color: $amaranth-color;
}

.amaranth-color {
  color: $amaranth-color;
}

.b-amaranth-color {
  border-color: $amaranth-color;
}

// scarlet
.ds-cff4d4d {
  color: #ff4d4d;
}

.ds-bgff4d4d {
  background-color: #ff4d4d;
}

.ds-bgFBE7E6 {
  background-color: #fbe7e6;
}

.ds-bg1D741B {
  background-color: #1d741b;
}

.ds-c397EF5 {
  color: #397ef5;
}

.ds-bgdcffec {
  background-color: #dcffec;
}

.ds-bgEBF0FF {
  background-color: #ebf0ff;
}

.ds-bgFF860D {
  background-color: #ff860d;
}

.ds-bg3F1973 {
  background-color: #3f1973;
}

.ds-bg7E43CC {
  background-color: #7e43cc;
}

.ds-bg259815 {
  background-color: #259815;
}

.ds-c006AFF {
  color: #006aff;
}

.ds-b006AFF {
  background-color: #006aff;
}

.ds-bgF2DE95 {
  background-color: #f2de95;
}

.ds-bg2D4365 {
  background-color: #2d4365;
}

.ds-bF4F6FF {
  background-color: #f4f6ff;
}

.ds-c2D4365 {
  color: #2d4365;
}

.ds-c919DAE {
  color: #919dae;
}

.ds-c7E84A3 {
  color: #7e84a3;
}

.ds-bF3F6FF {
  background: #f3f6ff;
}

.ds-bE8F0FF {
  background: #e8f0ff;
}

.ds-bE8F0FF {
  background: #e8f0ff;
}

.ds-bFCFCFC {
  background: #fcfcfc;
}

.ds-bgFFD23B {
  background: #ffd23b;
}

.ds-cC73737 {
  color: #c73737;
}

.ds-bgC73737 {
  background: #c73737;
}

.ds-cAE8C42 {
  color: #ae8c42;
}

.ds-bF5F5FF {
  background-color: #f5f5ff;
}

.ds-c848484 {
  color: #848484;
}

.ds-c9E9B9B {
  color: #9e9b9b;
}

.ds-cDB3B3B {
  color: #db3b3b;
}

.ds-c9A0C00 {
  color: #9a0c00;
}

.ds-c1A4682 {
  color: #1a4682;
}

.ds-c008B40 {
  color: #008b40;
}

.ds-cB57900 {
  color: #b57900;
}

.ds-c2B74D8 {
  color: #2b74d8;
}

.ds-c999999 {
  color: #999999;
}

.ds-c222222 {
  color: #222222;
}

.ds-c222222 {
  color: #222222;
}

.ds-bg2b74d8 {
  background-color: #2b74d8;
}
.ds-bg2b74d8 {
  background-color: #2b74d8;
}

.ds-bg999999 {
  background-color: #999999;
}

.ds-bgF5F9FD {
  background-color: #f5f9fd;
}

.ds-bgFCF3DE {
  background-color: #fcf3de;
}

.ds-bgEAF2FD {
  background-color: #eaf2fd;
}

.ds-bgE6F3EC {
  background-color: #e6f3ec;
}

.ds-bgTransparent {
  background-color: transparent;
}

.ds-c969696 {
  color: #969696;
}

.ds-c8C8C8C {
  color: #8c8c8c;
}

.ds-c4E4E4E {
  color: #4e4e4e;
}

// midnight color
.ds-c28292C {
  color: #28292c;
}

// star dust color
.ds-c9C9FAA {
  color: #9c9faa;
}

.ds-bgF54C52 {
  background-color: #f54c52;
}

.ds-bgffeaeb {
  background-color: #ffeaeb;
}

.ds-bgffefe0 {
  background-color: #ffefe0;
}

.ds-bgF4891E {
  color: #f4891e;
}

.ds-edffea {
  color: #edffea;
}

.ds-bg3D6AFD {
  color: #3d6afd;
}

.ds-cA6A6A6 {
  color: #a6a6a6;
}

.ds-c2C2C2C {
  color: #2c2c2c;
}

.ds-c929292 {
  color: #929292;
}

.ds-c4A5740 {
  color: #4a5740;
}

.ds-bg215BA8 {
  background-color: #215ba8;
}

.ds-bgF7F7F7 {
  background-color: #f7f7f7;
}

.ds-c999 {
  color: #999;
}

.ds-c2B74D8 {
  color: #2b74d8;
}

.ds-c9a0c00 {
  color: #9a0c00;
}

.ds-bgfbe7e6 {
  background: #fbe7e6;
}

.ds-bg2B74D8 {
  background: #2b74d8;
}

.ds-bgE6F3EC {
  background: #e6f3ec;
}

.ds-bgFCF3DE {
  background-color: #fcf3de;
}
.ds-cB57900 {
  color: #b57900;
}

.ds-c222222 {
  color: #222222;
}

.ds-c0E0E0E {
  color: #0e0e0e;
}

.ds-bg6C1F48 {
  background: #6c1f48;
}

.ds-bgF1EDFF {
  background-color: #f1edff;
}

.ds-bg5B488D {
  background-color: #5b488d;
}

.ds-bg008B40 {
  background-color: #008b40;
}

.ds-bgF1EDFF {
  background-color: #f1edff;
}

.ds-bg5B488D {
  background-color: #5b488d;
}

.ds-bg008B40 {
  background-color: #008b40;
}
