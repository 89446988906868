.subscription-testimonial__slider-dots {
  display: inline-block;
  vertical-align: middle;
  margin: auto;
  padding: 0;
  padding: 20px 0 0px 0;
  text-align: center;
}

.subscription-testimonial__slider-dots li {
  list-style: none;
  cursor: pointer;
  display: inline-block;
  margin: 0 4px;
  padding: 0;
}

.subscription-testimonial__slider-dots li button {
  border: none;
  background: #585858;
  color: transparent;
  cursor: pointer;
  display: block;
  height: 10px;
  width: 10px;
  border-radius: 7.5px;
  padding: 0;
}

.subscription-testimonial__slider-dots li.slick-active button {
  padding: 0px;
  background: #fff 0% 0% no-repeat padding-box;
  border-radius: 50%;
  width: 10px;
  height: 10px;
}

.subscription-testimonial__slider-dots li button:hover,
.subscription-testimonial__slider-dots li button:focus {
  background: #fff;
  outline: 0;
}

.slick-slider.slick-initialized {
  width: 100%;
}
.slick-initialized .slick-slide {
  display: block;
  padding: 10px;
}
.slick-slide img {
  margin-left: auto !important;
  margin-right: auto !important;
}

.subscription-tooltip {
  position: relative;
  border-radius: 16px;
  background-color: white;
  color: #000000;
  padding: 0 20px;
  font-size: 14px;
  &::after {
    content: " ";
    width: 16px;
    height: 16px;
    position: absolute;
    top: 50%;
    right: -6px;
    transform: translateY(-50%) rotate(133deg);
    background-color: white;
  }
}
