.HelpSection {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  margin-left: 16px;

  .helpText {
    padding-left: 4px;
  }
}