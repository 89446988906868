.ds-p4 {
  padding: 4px;
}

.ds-mt2 {
  margin-top: 2px;
}
.ds-pt2 {
  padding-top: 2px;
}
.ds-pl2 {
  padding-left: 2px;
}
.ds-pv0 {
  padding-top: 0px;
  padding-bottom: 0px;
}

.ds-pt4 {
  padding-top: 4px;
}

.ds-pt6 {
  padding-top: 6px;
}

.ds-pr5 {
  padding: 5px;
}

.ds-pr6 {
  padding-right: 6px;
}

.ds-pb4 {
  padding-bottom: 4px;
}

.ds-mb4 {
  margin-bottom: 4px;
}
.ds-pb6 {
  padding-bottom: 6px;
}

.ds-pl4 {
  padding-left: 4px;
}
.ds-pl6 {
  padding-left: 6px;
}

.ds-pr4 {
  padding-right: 4px;
}

.ds-p8 {
  padding: 8px;
}

.ds-pt8 {
  padding-top: 8px;
}

.ds-pb8 {
  padding-bottom: 8px;
}
.ds-pb10 {
  padding-bottom: 10px;
}
.ds-pb30 {
  padding-bottom: 30px;
}

.ds-pl8 {
  padding-left: 8px;
}
.ds-pl10 {
  padding-left: 10px;
}
.ds-pr8 {
  padding-right: 8px;
}
.ds-pr10 {
  padding-right: 10px;
}
.ds-ph10 {
  padding-left: 10px;
  padding-right: 10px;
}
.ds-p12 {
  padding: 12px;
}
.ds-p14 {
  padding: 14px;
}
.ds-pt12 {
  padding-top: 12px;
}

.ds-pb12 {
  padding-bottom: 12px;
}

.ds-pb14 {
  padding-bottom: 14px;
}
.ds-pb76 {
  padding-bottom: 76px;
}
.ds-pl12 {
  padding-left: 12px;
}

.ds-pr12 {
  padding-right: 12px;
}

.ds-pr14 {
  padding-right: 14px;
}

.ds-p16 {
  padding: 16px;
}
.ds-p18 {
  padding: 18px;
}
.ds-pt14 {
  padding-top: 14px;
}
.ds-mt14 {
  margin-top: 14px;
}
.ds-mt16 {
  margin-top: 16px;
}
.ds-pt16 {
  padding-top: 16px;
}
.ds-pt18 {
  padding-top: 18px;
}
.ds-pr18 {
  padding-right: 18px;
}
.ds-pt42 {
  padding-top: 42px;
}
.ds-pt44 {
  padding-top: 44px;
}

.ds-pb16 {
  padding-bottom: 16px;
}

.ds-pl16 {
  padding-left: 16px;
}

.ds-pl18 {
  padding-left: 18px;
}
.ds-pr16 {
  padding-right: 16px;
}
.ds-p20 {
  padding: 20px;
}
.ds-pt26 {
  padding-top: 26px;
}

.ds-p22 {
  padding: 22px;
}

.ds-pt10 {
  padding-top: 10px;
}

.ds-pt20 {
  padding-top: 20px;
}

.ds-pb20 {
  padding-bottom: 20px;
}

.ds-pl20 {
  padding-left: 20px;
}

.ds-pl28 {
  padding-left: 28px;
}

.ds-pr20 {
  padding-right: 20px;
}

.ds-p24 {
  padding: 24px;
}
.ds-pt21 {
  padding-top: 21px;
}
.ds-pt24 {
  padding-top: 24px;
}
.ds-pt26 {
  padding-top: 26px;
}
.ds-pb22 {
  padding-bottom: 22px;
}
.ds-pb24 {
  padding-bottom: 24px;
}
.ds-pb26 {
  padding-bottom: 26px;
}
.ds-ml26 {
  margin-left: 26px;
}
.ds-pl22 {
  padding-left: 22px;
}
.ds-pl24 {
  padding-left: 24px;
}
.ds-pl26 {
  padding-left: 26px;
}
.ds-pr24 {
  padding-right: 24px;
}
.ds-pr26 {
  padding-right: 26px;
}
.ds-p28 {
  padding: 28px;
}

.ds-pt28 {
  padding-top: 28px;
}
.ds-pb26 {
  padding-bottom: 26px;
}

.ds-pb28 {
  padding-bottom: 28px;
}

.ds-pr28 {
  padding-right: 28px;
}

.ds-p32 {
  padding: 32px;
}

.ds-pl30 {
  padding-left: 30px;
}

.ds-pt32 {
  padding-top: 32px;
}
.ds-pt34 {
  padding-top: 34px;
}
.ds-pb32 {
  padding-bottom: 32px;
}
.ds-pb34 {
  padding-bottom: 34px;
}
.ds-pl32 {
  padding-left: 32px;
}
.ds-pl34 {
  padding-left: 34px;
}

.ds-pl38 {
  padding-left: 38px;
}

.ds-pr32 {
  padding-right: 32px;
}
.ds-pr34 {
  padding-right: 34px;
}
.ds-p36 {
  padding: 36px;
}

.ds-pt36 {
  padding-top: 36px;
}

.ds-pb36 {
  padding-bottom: 36px;
}

.ds-pl36 {
  padding-left: 36px;
}

.ds-pr36 {
  padding-right: 36px;
}
.ds-pr38 {
  padding-right: 38px;
}

.ds-p40 {
  padding: 40px;
}

.ds-pt30 {
  padding-top: 30px;
}

.ds-pt40 {
  padding-top: 40px;
}

.ds-pb40 {
  padding-bottom: 40px;
}

.ds-pl40 {
  padding-left: 40px;
}

.ds-pl42 {
  padding-left: 42px;
}
.ds-pr40 {
  padding-right: 40px;
}
.ds-pr42 {
  padding-right: 42px;
}
.ds-p44 {
  padding: 44px;
}

.ds-p55 {
  padding: 55px;
}
.ds-pt44 {
  padding-top: 44px;
}

.ds-pb44 {
  padding-bottom: 44px;
}

.ds-p48 {
  padding: 48px;
}

.ds-pt48 {
  padding-top: 48px;
}

.ds-pb48 {
  padding-bottom: 48px;
}

.ds-pl48 {
  padding-left: 48px;
}
.ds-pl54 {
  padding-left: 54px;
}
.ds-pl58 {
  padding-left: 58px;
}

.ds-pr48 {
  padding-right: 48px;
}

.ds-pr54 {
  padding-right: 54px;
}

.ds-pb55 {
  padding-bottom: 55px;
}
.ds-pt50 {
  padding-top: 50px;
}
.ds-pt55 {
  padding-top: 55px;
}
.ds-pl44 {
  padding-left: 44px;
}
.ds-pl150 {
  padding-left: 150px;
}
.ds-pl250 {
  padding-left: 250px;
}
.ds-pl77 {
  padding-left: 77px;
}
.ds-pr97 {
  padding-right: 97px;
}
.ds-pr90 {
  padding-right: 90px;
}
.ds-pr95 {
  padding-right: 95px;
}
.ds-pl95 {
  padding-left: 95px;
}
.ds-pr44 {
  padding-right: 44px;
}
.ds-pr60 {
  padding-right: 60px;
}
.ds-pr65 {
  padding-right: 65px;
}
.ds-pr78 {
  padding-right: 78px;
}
.ds-pt44 {
  padding-top: 44px;
}
.ds-pt64 {
  padding-top: 64px;
}
.ds-pt72 {
  padding-top: 72px;
}
.ds-pt82 {
  padding-top: 82px;
}

.ds-pt90 {
  padding-top: 90px;
}

.ds-pb90 {
  padding-bottom: 90px;
}
.ds-pb120 {
  padding-bottom: 120px;
}

.ds-pt150 {
  padding-top: 150px;
}

.ds-pb150 {
  padding-bottom: 150px;
}

.ds-pb250 {
  padding-bottom: 250px;
}

.ds-p24 {
  padding: 24px;
}
.ds-pv2 {
  padding-top: 2px;
  padding-bottom: 2px;
}
.ds-pv4 {
  padding-top: 4px;
  padding-bottom: 4px;
}
.ds-pv8 {
  padding-top: 8px;
  padding-bottom: 8px;
}
.ds-ph4 {
  padding-left: 4px;
  padding-right: 4px;
}
.ds-ph8 {
  padding-left: 8px;
  padding-right: 8px;
}
.ds-pv10 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.ds-ph12 {
  padding-left: 12px;
  padding-right: 12px;
}
.ds-ph14 {
  padding-left: 14px;
  padding-right: 14px;
}
.ds-ph16 {
  padding-left: 16px;
  padding-right: 16px;
}
.ds-pv12 {
  padding-top: 12px;
  padding-bottom: 12px;
}
.ds-pv16 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.ds-pv18 {
  padding-top: 18px;
  padding-bottom: 18px;
}
.ds-pv20 {
  padding-top: 20px;
  padding-bottom: 20px;
}
.ds-pv25 {
  padding-top: 25px;
  padding-bottom: 25px;
}
.ds-pv24 {
  padding-top: 24px;
  padding-bottom: 24px;
}
.ds-ph24 {
  padding-left: 24px;
  padding-right: 24px;
}
.ds-pv28 {
  padding-top: 28px;
  padding-bottom: 28px;
}
.ds-pv32 {
  padding-top: 32px;
  padding-bottom: 32px;
}
.ds-pv40 {
  padding-top: 40px;
  padding-bottom: 40px;
}
.ds-pv6 {
  padding-top: 6px;
  padding-bottom: 6px;
}
.ds-pv60 {
  padding-top: 60px;
  padding-bottom: 60px;
}
.ds-pv80 {
  padding-top: 80px;
  padding-bottom: 80px;
}
.ds-ph28 {
  padding-left: 28px;
  padding-right: 28px;
}
.ds-ph20 {
  padding-left: 20px;
  padding-right: 20px;
}
.ds-ph30 {
  padding-left: 30px;
  padding-right: 30px;
}
.ds-ph36 {
  padding-left: 36px;
  padding-right: 36px;
}
.ds-ph40 {
  padding-left: 40px;
  padding-right: 40px;
}
.ds-ph44 {
  padding-left: 44px;
  padding-right: 44px;
}
.ds-ph50 {
  padding-left: 50px;
  padding-right: 50px;
}
.ds-ph100 {
  padding-left: 100px;
  padding-right: 100px;
}
.ds-ph170 {
  padding-left: 170px;
  padding-right: 170px;
}
