.md-text8 {
  font-size: 8px;
}

.md-text10 {
  font-size: 10px;
}

.md-text11 {
  font-size: 11px;
}

.md-text12 {
  font-size: 12px;
}

.md-text13 {
  font-size: 13px;
}

.md-text14 {
  font-size: 14px;
}

.md-text15 {
  font-size: 15px;
}

.md-text16 {
  font-size: 16px;
}

.md-text17 {
  font-size: 17px;
}

.md-text18 {
  font-size: 18px;
}

.md-text19 {
  font-size: 19px;
}

.md-text20 {
  font-size: 20px;
}

.md-text21 {
  font-size: 21px;
}

.md-text22 {
  font-size: 22px;
}

.md-text23 {
  font-size: 23px;
}

.md-text24 {
  font-size: 24px;
}

.md-text25 {
  font-size: 25px;
}

.md-text26 {
  font-size: 26px;
}

.md-text27 {
  font-size: 27px;
}

.md-text28 {
  font-size: 28px;
}

.md-text29 {
  font-size: 29px;
}

.md-text30 {
  font-size: 30px;
}

.md-text31 {
  font-size: 31px;
}

.md-text32 {
  font-size: 32px;
}

.md-text33 {
  font-size: 33px;
}

.md-text34 {
  font-size: 34px;
}
.md-text38 {
  font-size: 38px;
}

.md-text41 {
  font-size: 41px;
}
.md-text48 {
  font-size: 48px;
}
.md-text59 {
  font-size: 59px;
}
.md-text68 {
  font-size: 68px;
}

.md-text72 {
  font-size: 72px;
}
.md-text101 {
  font-size: 101px;
}
.md-text174 {
  font-size: 174px;
}
.md-f300 {
  font-weight: 300;
}

.md-f400 {
  font-weight: 400;
}

.md-f500 {
  font-weight: 500;
}

.md-f600 {
  font-weight: 600;
}

.md-f700 {
  font-weight: 700;
}

.md-f800 {
  font-weight: 800;
}

.tdecnone {
  text-decoration: none;
  color: inherit;
}
