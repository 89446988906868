.ds-m0 {
  margin: 0;
}
.ds-m2 {
  margin: 2px;
}
.ds-mb3 {
  margin-bottom: 3px;
}
.ds-m4 {
  margin: 4px;
}
.ds-mt3 {
  margin-top: 3px;
}
.ds-mt4 {
  margin-top: 4px;
}

.ds-mt6 {
  margin-top: 6px;
}

.ds-ml6 {
  margin-left: 6px;
}

.ds-mr6 {
  margin-right: 6px;
}

.ds-mb4 {
  margin-bottom: 4px;
}
.ds-ml2 {
  margin-left: 2px;
}

.ds-ml4 {
  margin-left: 4px;
}

.ds-mr4 {
  margin-right: 4px;
}

.ds-m8 {
  margin: 8px;
}

.ds-mt8 {
  margin-top: 8px;
}
.ds-mb6 {
  margin-bottom: 6px;
}

.ds-mb8 {
  margin-bottom: 8px;
}

.ds-mr8 {
  margin-right: 8px;
}

.ds-m10 {
  margin: 10px;
}

.ds-mt10 {
  margin-top: 10px;
}

.ds-mb10 {
  margin-bottom: 10px;
}

.ds-ml4 {
  margin-left: 4px;
}

.ds-ml8 {
  margin-left: 8px;
}

.ds-ml10 {
  margin-left: 10px;
}

.ds-mr10 {
  margin-right: 10px;
}

.ds-m12 {
  margin: 12px;
}

.ds-mt12 {
  margin-top: 12px;
}

.ds-mt16 {
  margin-top: 16px;
}

.ds-mt18 {
  margin-top: 18px;
}

.ds-mb12 {
  margin-bottom: 12px;
}

.ds-ml12 {
  margin-left: 12px;
}

.ds-mr12 {
  margin-right: 12px;
}

.ds-mb14 {
  margin-bottom: 14px;
}

.ds-mr16 {
  margin-right: 16px;
}
.ds-mr120 {
  margin-right: 120px;
}
.ds-m16 {
  margin: 16px;
}

.ds-mt16 {
  margin-top: 16px;
}

.ds-mb16 {
  margin-bottom: 16px;
}

.ds-ml16 {
  margin-left: 16px;
}
.ds-ml18 {
  margin-left: 18px;
}

.ds-mr18 {
  margin-right: 18px;
}

.ds-m20 {
  margin: 20px;
}

.ds-mt1 {
  margin-top: 1px;
}

.ds-mt2 {
  margin-top: 2px;
}

.ds-mt20 {
  margin-top: 20px;
}

.ds-mb18 {
  margin-bottom: 18px;
}

.ds-mb20 {
  margin-bottom: 20px;
}

.ds-mb22 {
  margin-bottom: 22px;
}

.ds-ml20 {
  margin-left: 20px;
}

.ds-ml22 {
  margin-left: 22px;
}

.ds-mr20 {
  margin-right: 20px;
}

.ds-m24 {
  margin: 24px;
}

.ds-mt24 {
  margin-top: 24px;
}

.ds-mb24 {
  margin-bottom: 24px;
}

.ds-ml24 {
  margin-left: 24px;
}

.ds-mr24 {
  margin-right: 24px;
}

.ds-mt26 {
  margin-top: 26px;
}

.ds-mb26 {
  margin-bottom: 26px;
}

.ds-ml26 {
  margin-left: 26px;
}

.ds-mr26 {
  margin-right: 26px;
}

.ds-m28 {
  margin: 28px;
}

.ds-mt28 {
  margin-top: 28px;
}

.ds-mb28 {
  margin-bottom: 28px;
}

.ds-mr28 {
  margin-right: 28px;
}

.ds-ml28 {
  margin-left: 28px;
}

.ds-mb30 {
  margin-bottom: 30px;
}

.ds-mt30 {
  margin-top: 30px;
}

.ds-ml30 {
  margin-left: 30px;
}

.ds-m32 {
  margin: 32px;
}

.ds-mt32 {
  margin-top: 32px;
}

.ds-ml32 {
  margin-left: 32px;
}

.ds-mr32 {
  margin-right: 32px;
}

.ds-mb32 {
  margin-bottom: 32px;
}

.ds-mt34 {
  margin-top: 34px;
}

.ds-mr35 {
  margin-right: 35px;
}

.ds-m36 {
  margin: 36px;
}

.ds-mt36 {
  margin-top: 36px;
}
.ds-mt38 {
  margin-top: 38px;
}

.ds-mb36 {
  margin-bottom: 36px;
}

.ds-ml36 {
  margin-left: 36px;
}

.ds-mr36 {
  margin-right: 36px;
}
.ds-mr38 {
  margin-right: 38px;
}

.ds-m40 {
  margin: 40px;
}

.ds-mt40 {
  margin-top: 40px;
}

.ds-mt42 {
  margin-top: 42px;
}

.ds-mb40 {
  margin-bottom: 40px;
}

.ds-mb42 {
  margin-bottom: 42px;
}
.ds-mb80 {
  margin-bottom: 80px;
}

.ds-ml40 {
  margin-left: 40px;
}

.ds-mt53 {
  margin-top: 53px;
}

.ds-ml55 {
  margin-left: 55px;
}
.ds-mr56 {
  margin-right: 56px;
}
.ds-ml57 {
  margin-left: 57px;
}
.ds-ml62 {
  margin-left: 62px;
}
.ds-ml78 {
  margin-left: 78px;
}

.ds-mr40 {
  margin-right: 40px;
}

.ds-mr50 {
  margin-right: 50px;
}

.ds-mr60 {
  margin-right: 60px;
}

.ds-mr66 {
  margin-right: 66px;
}

.ds-mr106 {
  margin-right: 106px;
}
.ds-mt50 {
  margin-top: 50px;
}
.ds-mt54 {
  margin-top: 54px;
}
.ds-mt56 {
  margin-top: 56px;
}

.ds-mt80 {
  margin-top: 80px;
}
.ds-mt86 {
  margin-top: 86px;
}

.ds-mt84 {
  margin-top: 84px;
}

.ds-mt88 {
  margin-top: 88px;
}

.ds-m44 {
  margin: 44px;
}

.ds-mt44 {
  margin-top: 44px;
}
.ds-mt46 {
  margin-top: 46px;
}

.ds-mb44 {
  margin-bottom: 44px;
}

.ds-ml42 {
  margin-left: 42px;
}

.ds-ml44 {
  margin-left: 44px;
}

.ds-mr44 {
  margin-right: 44px;
}

.ds-m48 {
  margin: 48px;
}

.ds-mt48 {
  margin-top: 48px;
}

.ds-mb48 {
  margin-bottom: 48px;
}

.ds-ml48 {
  margin-left: 48px;
}
.ds-ml86 {
  margin-left: 86px;
}
.ds-ml96 {
  margin-left: 96px;
}
.ds-ml110 {
  margin-left: 110px;
}
.ds-ml145 {
  margin-left: 145px;
}
.ds-mr48 {
  margin-right: 48px;
}

.ds-mr100 {
  margin-right: 100px;
}
.ds-mb60 {
  margin-bottom: 60px;
}
.ds-mb66 {
  margin-bottom: 66px;
}

.ds-pt60 {
  padding-top: 60px;
}

.ds-mt60 {
  margin-top: 60px;
}
.ds-mt64 {
  margin-top: 64px;
}
.ds-mt65 {
  margin-top: 65px;
}
.ds-mt68 {
  margin-top: 68px;
}

.ds-mb90 {
  margin-bottom: 90px;
}

.ds-mt90 {
  margin-top: 90px;
}
.ds-mt100 {
  margin-top: 100px;
}

.ds-mt115 {
  margin-top: 115px;
}
.ds-mt126 {
  margin-top: 126px;
}

.ds-mb126 {
  margin-bottom: 126px;
}
.ds-mt150 {
  margin-top: 150px;
}
.ds-mt130 {
  margin-top: 130px;
}
.ds-mb150 {
  margin-bottom: 150px;
}

.ds-mt250 {
  margin-top: 250px;
}
.ds-mb250 {
  margin-bottom: 250px;
}

.ds-mh66 {
  margin-left: 66px;
  margin-right: 66px;
}

.ds-mh16 {
  margin-left: 16px;
  margin-right: 16px;
}

.ma-auto {
  margin: auto;
}

.ds-mh2 {
  margin-left: 2px;
  margin-right: 2px;
}
